<template>
  <div class="v-contact">
    <mi-title title="聯絡我們" color="white" />

    <div class="g-content">
      <div class="v-contact__mobile-content g-content">
        <div class="mobile-content-tab">
          <!-- FIXME 先借用 search-tabs，調整時要調整一下字 -->
          <search-tabs :tabs="searchTabs" v-model:activeTab="activeTab" @handleTab="handleTab" />
        </div>
        <div class="mobile-content-content">
          <template v-if="activeTab === 'leaveMessage'">
            <leave-message />
          </template>
          <template v-if="activeTab === 'contactInfo'">
            <contact-info />
          </template>
        </div>
      </div>
      <div class="v-contact__tablet-content g-content">
        <div class="contact-content ">
          <div class="contact-content__leave-message">
            <red-decorate-title title="留言給我們" size="large" />
            <leave-message />
          </div>
          <div class="contact-content__contact-info">
            <red-decorate-title title="聯絡資訊" size="large" />
            <contact-info />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import MiTitle from '@/components/Global/MiTitle.vue';
import contactBgImg from '@/assets/contact/bg.png';
import { useSiteStore } from '@/store/site';
import LeaveMessage from '@/components/Front/LeaveMessage.vue';
import ContactInfo from '@/components/Front/ContactInfo.vue';
import RedDecorateTitle from '@/components/Global/RedDecorateTitle.vue';
import SearchTabs from '@/components/SearchResult/SearchTabs.vue';

export default defineComponent({
  name: 'Contact',
  components: {
    MiTitle,
    LeaveMessage,
    ContactInfo,
    RedDecorateTitle,
    SearchTabs,
  },
  setup() {
    const siteStore = useSiteStore();
    siteStore.setBackgroundImage(contactBgImg);

    const activeTab = ref('leaveMessage');

    const searchTabs = ref([
      {
        label: '留言給我們',
        key: 'leaveMessage',
      },
      {
        label: '聯絡資訊',
        key: 'contactInfo',
      },
    ]);

    const handleTab = (key) => {
      activeTab.value = key;
    };

    // 離開此頁時，清除背景
    onBeforeRouteLeave(() => {
      siteStore.setBackgroundImage(false);
    });

    return {
      activeTab,
      searchTabs,
      handleTab,
    };
  },
});
</script>
<style lang="scss" scoped>
.v-contact {
  padding-top: 60px;
  padding-bottom: 80px;

  &__mobile-content {
    background-color: $c-white;
    border-radius: 10px;
    @include padding(27px 20px);
  }

  &__tablet-content {
    display: none;
  }
}

.mobile-content-tab {
  @include flex();

  &__box {
    & + & {
      margin-left: 24px;
    }
  }
}

@media screen and (min-width: $tablet) {
  .v-contact {
    padding-top: 80px;
    padding-bottom: 150px;

    &__mobile-content {
      display: none;
    }

    &__tablet-content {
      display: block;
      max-width: 1366px;
      background-color: $c-white;
      border-radius: 10px;
      padding: 60px 60px 90px;
      position: relative;
      box-shadow: 0px 0px 10px rgba($c-black, 0.05);
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 609px;
      background-image: url('~@/assets/myMusic/bg_deco.png');
    }

    .contact-content {

      @include flex(flex-start, flex-start);
      width: 100%;
      height: 100%;

      &__leave-message {
        flex: none;
        width: 50%;
        border-right: 1px solid $c-assist2;
        @include padding(0 88px 0 0);
      }

      &__contact-info {
        flex: none;
        width: 50%;
        @include padding(0 0 0 88px);
      }
    }
  }
}

@media screen and (min-width: $sm-pc) {
  .v-contact {
    &__tablet-content {
      padding: 60px 130px 90px;
    }
  }
}
</style>
