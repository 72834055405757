<template>
  <div class="c-contact-info">
    <!-- <red-decorate-title title="聯絡資訊" size="large" /> -->
    <div class="info-wrap">
      <ul class="info-list">
        <li class="info-list__item">
          <div class="item-box">
            <img class="item-box__icon" src="@/assets/contact/icon_phone.svg" />
            <p class="item-box__text">02-8787-4775</p>
          </div>
        </li>
        <li class="info-list__item">
          <div class="item-box">
            <img class="item-box__icon" src="@/assets/contact/icon_mail.svg" />
            <p class="item-box__text">service@pointgroup.com.tw</p>
          </div>
        </li>

        <li class="info-list__item">
          <div class="item-box">
            <img class="item-box__icon" src="@/assets/contact/icon_map.svg" />
            <div class="item-box__text_map">
              <p class="item-box__text_map__text">台北市松山區八德路四段165號4樓</p>
              <a class="google-map-box" rel="noreferrer noopener" target="_blank" href="https://www.google.com.tw/maps/search/25.048815,121.56277/@25.048815,121.56277,19z" >
                <p class="google-map-box__text">Google map</p>
                <img class="google-map-box__open-icon" src="@/assets/contact/icon_map_link.svg" />
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
// import RedDecorateTitle from '@/components/Global/RedDecorateTitle.vue';

export default defineComponent({
  name: 'ContactInfo',
  components: {
    // RedDecorateTitle,
  },
});
</script>
<style lang="scss" scoped>
.info-wrap {
  margin-top: 50px;
}

.info-list {
  &__item {
    & + & {
      margin-top: 30px;
    }
  }
}

.item-box {
  @include flex(flex-start, flex-start);

  &__icon {
    width: 25px;
  }

  &__text {
    @include font-style($c-black, 18, normal, 0.36px);
    margin-left: 20px;
  }

  &__text_map {
    margin-left: 20px;

    &__text {
      @include font-style($c-black, 18, normal, 0.36px);
    }
  }
}

.google-map-box {
  @include inline-flex(center);
  border: 1px solid $c-main;
  height: 26px;
  border-radius: 13px;
  margin-top: 23px;
  padding: 0 10px;

  &__text {
    @include font-style($c-main, 14, normal, 0.7px);
  }

  &__open-icon {
    width: 8px;
    margin-left: 7px;
  }
}
</style>
