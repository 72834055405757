<template>
  <div class="c-leave-message">
    <!-- <red-decorate-title title="留言給我們" size="large" /> -->

    <div class="leave-message-wrap">
      <n-form :label-width="80" :model="model" :rules="rules" :size="size" ref="formRef" label-placement="top">
        <n-grid :cols="12" :x-gap="20">
          <n-form-item-gi :span="6" label="姓名" path="name">
            <n-input placeholder="請輸入姓名" v-model:value="model.name" />
          </n-form-item-gi>

          <n-form-item-gi :span="6" label="稱謂" path="gender">
            <n-radio-group v-model:value="model.gender" name="radiogroup1">
              <n-space>
                <n-radio value="1">先生</n-radio>
                <n-radio value="2">小姐</n-radio>
              </n-space>
            </n-radio-group>
          </n-form-item-gi>

          <n-form-item-gi :span="12" label="電子信箱" path="email">
            <n-input placeholder="請輸入 Email" v-model:value="model.email" />
          </n-form-item-gi>

          <n-form-item-gi :span="12" label="問題類型" path="questionType">
            <n-select placeholder="請選擇問題類型" :options="generalOptions" v-model:value="model.questionType" multiple />
          </n-form-item-gi>

          <n-form-item-gi :span="12" label="問題內容" path="email">
            <div class="text-remaining">
              <n-input placeholder="請輸入問題" type="textarea" v-model:value="model.questionType" :rows="8" />
              <p class="text-remaining__text">0/1000</p>
            </div>
          </n-form-item-gi>
        </n-grid>
      </n-form>
    </div>

    <div class="btn-wrap">
      <mi-button>Submit</mi-button>

    </div>
  </div>
</template>
<script>

import { defineComponent, ref } from 'vue';
import {
  NForm, NInput, NFormItemGi, NGrid, NSpace, NRadio, NSelect,
} from 'naive-ui';
// import RedDecorateTitle from '@/components/Global/RedDecorateTitle.vue';
import MiButton from '@/components/Global/MiButton.vue';

export default defineComponent({
  name: 'LeaveMessage',
  components: {
    NForm,
    NInput,
    NGrid,
    NFormItemGi,
    NSpace,
    NRadio,
    NSelect,
    MiButton,
    // RedDecorateTitle,
  },
  setup() {
    return {
      model: ref({
        name: '',
        gender: '',
        nationality: '',
        questionType: '',
        questionContent: '',

      }),
      rules: {
        name: {
          required: true,
        },
        gender: {
          required: true,
        },
        email: {
          required: true,
        },
        questionType: {
          required: true,
        },
        questionContent: {
          required: true,
        },

      },
      generalOptions: ['groode', 'veli good', 'emazing', 'lidiculous'].map((v) => ({
        label: v,
        value: v,
      })),
    };
  },
});
</script>
<style lang="scss" scoped>

.leave-message-wrap {
  margin-top: 50px;
}

.text-remaining {
  width: 100%;

  &__text {
    @include font-style($c-assist3, 12, normal, 0.24px, 24px);
    text-align: right;
  }
}

.btn-wrap {
  margin-top: 50px;
  @include flex(center);
}

</style>
